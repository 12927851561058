.ql-container
    font-family: "Source Sans Pro", sans-serif
    font-size: 16px

.ql-editor
    padding: 8px 12px

.ql-editor.ql-blank::before
    font-style: normal

.quill-flush quill-view .ql-editor
    padding: 0