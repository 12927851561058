@import "variables"

ngb-datepicker
    box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.05)

@media (max-width: 576px)
    ngb-datepicker:not(.inline)
        transform: translateX(-50%) !important
        left: 50% !important
        top: $modal-dialog-margin !important

.ngb-dp-header
    min-width: 252px !important
    padding: 16px 16px 0 16px !important
    background-color: $white !important
    border-radius: $border-radius-lg !important

.ngb-dp-navigation-select > .form-select
    height: auto !important
    padding: 0.375rem 2.25rem 0.375rem 0.75rem !important

.ngb-dp-month-name
    flex: 1 1 auto !important
    order: 0
    text-align: start !important
    background-color: $white !important
    color: var(--app-primary-colour)
    font-size: $font-size-large !important
    font-weight: 600 !important
    line-height: $line-height-large !important

ngb-datepicker * .ngb-dp-arrow
    flex: 0 0 auto !important
    width: auto !important
    height: auto !important
    order: 1

.ngb-dp-navigation-chevron
    width: auto !important
    height: auto !important
    margin: 8px 12px !important
    padding: 0.25rem !important
    border-width: 0.15rem 0.15rem 0 0 !important
    color: $charcoal-900

.ngb-dp-arrow-btn
    background-color: $white !important
    padding: 4px 6px 0 6px !important
    border: 1px solid $charcoal-50 !important
    margin: 0 !important
    &:hover
        background-color: $charcoal-25 !important
        border: 1px solid $charcoal-100 !important
    &:active
        background-color: $charcoal-50 !important
        border: 1px solid $charcoal-50 !important

ngb-datepicker-navigation
    gap: 0.5rem !important

ngb-datepicker-navigation-select
    flex: 1 1 auto !important
    gap: 0.5rem !important

.ngb-dp-month
    padding: 1rem
    flex: 1 1 auto !important
    max-width: 320px !important

.ngb-dp-week
    justify-content: space-evenly !important
    gap: 0.25rem !important

.ngb-dp-weekdays
    background-color: $charcoal-25 !important
    border-bottom: none !important
    border-radius: $border-radius-sm !important
    margin-bottom: 12px !important

.ngb-dp-weekday, .ngb-dp-week-number
    color: $charcoal-300 !important
    font-style: normal !important

.ngb-dp-weekday
    width: auto !important
    min-width: 32px !important
    height: 32px !important
    color: $charcoal-900 !important
    font-size: $font-size-small !important
    font-weight: 600 !important

.ngb-dp-day
    width: 42px !important
    height: 42px !important

.custom-day
    padding: 0.3rem !important

[ngbDatepickerDayView]
    width: auto !important
    height: 32px !important
    border-radius: 10px !important
    font-size: $font-size-large !important
    &:active
        background-color: $charcoal-50 !important
        font-size: $font-size-large * 1.1 !important

.ngb-dp-today > [ngbDatepickerDayView]
    background-color: $charcoal-50

ngb-datepicker.inline
    display: flex !important
    flex-direction: column !important
    align-items: stretch !important
    justify-content: center !important
    padding: 8px 0 0 0 !important
    background-color: $white !important
    border: none !important
    box-shadow: none !important
    * .ngb-dp-weekdays
        background-color: inherit !important

ngb-datepicker.compact
    .ngb-dp-header
        padding: 0 8px 0 8px !important

    * .ngb-dp-month-name
        order: initial !important

    * .ngb-dp-navigation-chevron
        margin: 8px 8px !important
        padding: 0.2rem !important

    .ngb-dp-months
        justify-content: center !important

    * .ngb-dp-month
        padding: 0 !important

    * .ngb-dp-weekdays
        margin-bottom: 4px !important

    * .ngb-dp-weekday
        height: 28px !important
        margin-left: 0 !important
        margin-right: 0 !important

    * .ngb-dp-day
        width: auto !important
        min-width: 34px !important
        height: 32px !important
        margin-left: 0 !important
        margin-right: 0 !important

    * [ngbDatepickerDayView]
        height: 32px !important
        margin-left: 2px !important
        font-size: $font-size-md !important
        line-height: 2rem !important
        &:active
            font-size: $font-size-md * 1.1 !important
