@import "variables"

@import 'bootstrap/scss/bootstrap'
@import 'bootstrap-icons/font/bootstrap-icons'
@import "prismjs/themes/prism.min.css"
@import "@fontsource/source-sans-pro"
@import "@fontsource/source-sans-pro/400.css"
@import "@fontsource/source-sans-pro/600.css"
@import "@fontsource/source-sans-pro/700.css"
@import 'intl-tel-input/build/css/intlTelInput.css'
@import 'quill/dist/quill.bubble.css'
@import 'quill'

@import "date-picker"
@import "colours"
@import "buttons"
@import "dropdown"
@import "tel-input"

a
    color: var(--app-primary-colour)

body
    background: transparent
    font-family: "Source Sans Pro", sans-serif

.bg-primary
    background-color: var(--app-primary-colour) !important

.text-primary
    color: var(--app-primary-colour) !important

a.primary
    color: var(--app-primary-colour) !important

.form-check-input[type=checkbox]
    height: 24px
    width: 24px

.form-check-input:checked
    background-color: var(--app-primary-colour) !important
    border-color: var(--app-primary-colour) !important

.w-stretch-0
    width: 0
    min-width: 100%

@media (min-width: 768px)
    .w-md-50
        width: 50% !important

.text-pre-wrap
    white-space: pre-wrap

.two-column-grid
    grid-row-gap: 8px
    grid-column-gap: 8px
    grid-template-columns: calc(50% - 0.3rem) calc(50% - 0.3rem)

.three-column-grid-sm-two-column-grid
    @extend .two-column-grid
    @media (min-width: 576px)
        grid-template-columns: calc(33.33% - 0.33rem) calc(33.33% - 0.33rem) calc(33.33% - 0.33rem)
