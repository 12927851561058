@import "colours"

$danger: $red-700
$danger-bg-subtle: $red-25
$danger-border-subtle: $red-100
$danger-text-emphasis: $red-700
$primary: $green-700
$light: $charcoal-25
$info: $teal-700
$info-bg-subtle: $teal-25

$alert-border-radius: 0.625rem
$alert-padding-y: 0.75rem
$alert-padding-x: 1rem

$font-size-xs: 0.815rem
$font-size-small: 0.875rem
$font-size-md: 1rem
$font-size-large: 1.125rem
$font-size-display-small: 1.5rem

$line-height-xs: 1.125rem
$line-height-small: 1.25rem
$line-height-md: 1.5rem
$line-height-large: 1.75rem
$line-height-display-small: 2rem

$border-color: $charcoal-50
$border-radius-sm: 0.5rem
$border-radius-lg: 0.625rem
$border-radius-xl: 0.75rem
$border-radius-xxl: 1rem
$border-radius: $border-radius-xl

$btn-border-width: 1px
$btn-border-radius: $border-radius
$btn-border-radius-lg: $border-radius
$btn-border-radius-sm: $border-radius
$btn-line-height: 1.5rem
$btn-padding-x-sm: 12px - $btn-border-width
$btn-padding-y-sm: 8px - $btn-border-width
$btn-padding-x: 16px - $btn-border-width
$btn-padding-y: 8px - $btn-border-width
$btn-padding-x-lg: 24px - $btn-border-width
$btn-padding-y-lg: 14px - $btn-border-width
$btn-font-size-sm: 0.875rem
$btn-font-size: 1rem
$btn-font-size-lg: 1.125rem

$hr-border-color: $charcoal-50

$modal-header-padding: 0.75rem 1.25rem
$modal-footer-padding: 0.5rem 1.25rem
$modal-content-border-radius: $border-radius-xxl
$modal-footer-bg: $charcoal-25
$modal-footer-border-width: 0
$modal-inner-padding: 0.75rem
$modal-lg: 582px
$modal-dialog-margin: 1rem

$input-border-color: $charcoal-50
$input-border-radius-sm: 0.625rem
$input-border-radius: 0.625rem
$input-border-radius-lg: 0.625rem
$input-placeholder-color: $charcoal-400

$form-check-input-border-radius: 6px


$list-group-color: $charcoal-700

$table-th-font-weight: 600

$nav-pills-border-radius: 0
$nav-pills-link-active-bg: #FFFFFF
$nav-link-padding-x: 0.5rem
$nav-link-padding-y: 0.5rem

@import 'bootstrap/scss/functions'
@import 'bootstrap/scss/variables'
