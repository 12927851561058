@import "variables"

.btn-close
    opacity: 0.7

button.btn, .btn-group .btn
    font-weight: $font-weight-semibold
    border-width: 1px

.btn.disabled
    opacity: 1

@media only screen and (max-width: 576px)
    .btn:not(.btn-block)
        width: 100%

.btn-carousel-item
    border-radius: 4px
    padding-left: 2px
    padding-bottom: 2px
    border: 0
    background-color: $charcoal-100

    &:hover
        background-color: $charcoal-200

.btn-carousel-item.active
    background-color: $charcoal-300

    &:hover
        background-color: $charcoal-300

// Primary

.btn-primary
    background-color: var(--app-primary-colour)
    border-color: var(--app-primary-colour)
    color: $white

.btn-primary:hover
    background-color: var(--app-primary-colour-light)
    border-color: var(--app-primary-colour-light)
    color: $white

.btn-primary:focus
    background-color: var(--app-primary-colour)
    border-color: var(--app-primary-colour)
    color: $white

.btn-primary:first-child:active, .btn-primary.active, .btn-primary:not(.btn-check):active
    background-color: var(--app-primary-colour-dark)
    border-color: var(--app-primary-colour-dark)
    color: $white

.btn-primary:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

// Secondary

.btn-light
    background-color: $charcoal-25
    border-color: $charcoal-50
    color: $charcoal-900

.btn-light:hover
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-light:focus
    background-color: $charcoal-25
    border-color: $charcoal-50
    color: $charcoal-900

.btn-light:first-child:active, .btn-light.active, .btn-light:not(.btn-check):active
    background-color: $charcoal-100
    border-color: $charcoal-200
    color: $charcoal-900

.btn-light:disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

// Link

.btn-link
    color: var(--app-primary-colour)

.btn-link:hover
    color: var(--app-primary-colour-light)

.btn-link:focus
    color: var(--app-primary-colour)

.btn-link:active
    color: var(--app-primary-colour-dark)

.btn-link:disabled
    color: $charcoal-300

// Info

.btn-info
    background-color: $teal-700
    border-color: $teal-700
    color: $white

.btn-info:hover
    background-color: $teal-500
    border-color: $teal-500
    color: $white

.btn-info:focus
    background-color: $teal-700
    border-color: $teal-700
    color: $white

.btn-info:first-child:active, .btn-info.active
    background-color: $teal-800
    border-color: $teal-800
    color: $white

.btn-info:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

// Primary

.btn-outline-primary
    background-color: $white
    border-color: $charcoal-50
    color: $green-700

.btn-outline-primary:hover, .btn-check + .btn-outline-primary:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $green-700

.btn-outline-primary:focus
    background-color: $white
    border-color: $charcoal-200
    color: $green-700

.btn-outline-primary:first-child:active,
.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-check:checked + .btn-outline-primary,
:not(.btn-check) + .btn-outline-primary:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $green-900

// Secondary

.btn-outline-secondary
    background-color: $white
    border-color: $charcoal-50
    color: $charcoal-900

.btn-outline-secondary:hover, .btn-check + .btn-outline-secondary:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-secondary:focus
    background-color: $white
    border-color: $charcoal-200
    color: $charcoal-900

.btn-outline-secondary:first-child:active,
.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-check:checked + .btn-outline-secondary,
:not(.btn-check) + .btn-outline-secondary:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

// Danger

.btn-danger
    background-color: $red-700
    border-color: $red-700
    color: $white


.btn-outline-danger
    background-color: $white
    border-color: $charcoal-50
    color: $red-700

.btn-outline-danger:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $red-700

.btn-outline-danger:focus
    background-color: $white
    border-color: $red-200
    color: $red-700

.btn-outline-danger:first-child:active,
.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-check:checked + .btn-outline-danger,
:not(.btn-check) + .btn-outline-danger:active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $red-900

.btn-outline-danger:first-child:active, .btn-outline-danger.active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $red-900

.btn-outline-danger:disabled
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $charcoal-300

.btn-outline-dark
    background-color: $white
    border-color: $charcoal-50
    color: $charcoal-900

.btn-outline-dark:hover, .btn-check + .btn-outline-dark:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-dark:focus
    background-color: $white
    border-color: $charcoal-200
    color: $charcoal-900

.btn-outline-dark:first-child:active,
.btn-outline-dark.active,
.btn-outline-dark:active,
.btn-check:checked + .btn-outline-dark,
:not(.btn-check) + .btn-outline-dark:active
    background-color: $charcoal-50
    border-color: $charcoal-100
    color: $charcoal-900

.btn-outline-dark:disabled, .btn-outline-dark.disabled
    background-color: $charcoal-25
    border-color: $charcoal-25
    color: $charcoal-700

// Plain

.btn-plain
    background-color: transparent
    border-color: transparent
    color: $charcoal-700

.btn-plain:hover
    background-color: transparent
    border-color: transparent
    color: $charcoal-900

.btn-plain:focus
    background-color: transparent
    border-color: transparent
    color: $charcoal-700

.btn-plain:first-child:active,
.btn-plain.active,
.btn-plain:active,
.btn-check:checked + .btn-plain,
:not(.btn-check) + .btn-plain:active
    background-color: transparent
    border-color: transparent
    color: $charcoal-900

.btn-plain:disabled
    background-color: transparent
    border-color: transparent
    color: $charcoal-300

// Very Poor

.btn-outline-very-poor
    background-color: $red-25
    border-color: $red-100
    color: $red-700

.btn-outline-very-poor:hover,
.btn-check + .btn-outline-very-poor:hover
    background-color: $red-50
    border-color: $red-100
    color: $red-700

.btn-outline-very-poor:focus,
.btn-check:checked + .btn-outline-very-poor:checked
    background-color: $red-50
    border-color: $red-100
    color: $red-700

.btn-outline-very-poor:first-child:active,
.btn-outline-very-poor.active,
.btn-outline-very-poor:active,
.btn-check:checked + .btn-outline-very-poor
    background-color: $red-100
    border-color: $red-200
    color: $red-800

// Poor

.btn-outline-poor
    background-color: $orange-25
    border-color: $orange-100
    color: $orange-700

.btn-outline-poor:hover,
.btn-check + .btn-outline-poor:hover
    background-color: $orange-50
    border-color: $orange-100
    color: $orange-700

.btn-outline-poor:focus,
.btn-check:checked + .btn-outline-poor:checked
    background-color: $orange-50
    border-color: $orange-100
    color: $orange-700

.btn-outline-poor:first-child:active,
.btn-outline-poor.active,
.btn-outline-poor:active,
.btn-check:checked + .btn-outline-poor
    background-color: $orange-100
    border-color: $orange-300
    color: $orange-800

// Fair

.btn-outline-fair
    background-color: $white
    border-color: $charcoal-100
    color: $charcoal-700

.btn-outline-fair:hover, .btn-check + .btn-outline-fair:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-700

.btn-outline-fair:focus,
.btn-check:checked + .btn-outline-fair:checked
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $charcoal-700

.btn-outline-fair:first-child:active,
.btn-outline-fair.active,
.btn-outline-fair:active,
.btn-check:checked + .btn-outline-fair
    background-color: $charcoal-100
    border-color: $charcoal-300
    color: $charcoal-800

// Good

.btn-outline-good
    background-color: $green-25
    border-color: $green-100
    color: $green-700

.btn-outline-good:hover, .btn-check + .btn-outline-good:hover
    background-color: $green-50
    border-color: $green-100
    color: $green-700

.btn-outline-good:focus,
.btn-check:checked + .btn-outline-good:checked
    background-color: $green-50
    border-color: $green-100
    color: $green-700

.btn-outline-good:first-child:active,
.btn-outline-good.active,
.btn-outline-good:active,
.btn-check:checked + .btn-outline-good
    background-color: $green-100
    border-color: $green-300
    color: $green-800

// Excellent

.btn-outline-excellent
    background-color: $blue-25
    border-color: $blue-100
    color: $blue-700

.btn-outline-excellent:hover, .btn-check + .btn-outline-excellent:hover
    background-color: $blue-50
    border-color: $blue-100
    color: $blue-700

.btn-outline-excellent:focus,
.btn-check:checked + .btn-outline-excellent:checked
    background-color: $blue-50
    border-color: $blue-100
    color: $blue-700

.btn-outline-excellent:first-child:active,
.btn-outline-excellent.active,
.btn-outline-excellent:active,
.btn-check:checked + .btn-outline-excellent
    background-color: $blue-100
    border-color: $blue-300
    color: $blue-800

// Segmented Control

.btn-group

    & .btn
        font-weight: $font-weight-semibold
        border-width: 1px

    &.segmented-control
        border-radius: $border-radius-lg
        background-color: #7676801F
        padding: 2px !important

        & .btn
            background-color: $charcoal-50
            color: $charcoal-600
            border-radius: 9px !important

            &:not(:last-child)
                margin: 0 2px 0 0

            &:last-child
                margin: 0

            &:hover
                background-color: $charcoal-100
                color: $charcoal-600
                border: 1px solid $charcoal-50

        & .btn-sm
            padding: 0.25rem 0.5rem
            font-size: $font-size-base
            line-height: $line-height-base
            border-radius: $border-radius

.segmented-control input.btn-check:checked + .btn
    background-color: white
    color: black
    border: 0.5px solid rgba(0, 0, 0, 0.04)
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04)

label.btn-outline-secondary:has(input[type="radio"]:checked)
    background-color: $green-25
    border-color: $green-700
