@import "colours"
@import "variables"

.iti
    width: 100%
    display: flex
    flex-direction: row
    align-items: center

.iti .dropdown-menu
    border-radius: 0 0 $border-radius $border-radius
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2)

.iti .dropdown-menu.country-dropdown
    border-top-left-radius: 0
    border-top-right-radius: 0
    border-color: $charcoal-50
    margin-top: -1px
    width: auto
    right: 0
    inset: 100% 0 auto 50px !important

.iti .iti__country-list
    box-shadow: none
    font-size: $font-size-small
    margin-left: 0
    max-height: 170px

.iti__flag-container.open + input
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0

.iti .search-container input
    border-color: $charcoal-200
    border-radius: 0
    padding: 5px 10px

.iti .search-container input:focus
    outline: none

@media screen and (max-width: 479px)
    .iti .iti__country-list
        width: 88.3vw

ngx-intl-tel-input.ng-invalid.ng-touched input
    border: 1px solid #c0392b

ngx-intl-tel-input input:focus
    outline: none !important
    border-color: #3498db
    box-shadow: 0 0 0 0 #000

ngx-intl-tel-input input::-webkit-input-placeholder
    color: #bac2c7

ngx-intl-tel-input input:-ms-input-placeholder
    color: #bac2c7

ngx-intl-tel-input input::-ms-input-placeholder
    color: #bac2c7

ngx-intl-tel-input input::placeholder
    color: #bac2c7

ngx-intl-tel-input input[disabled]
    background-color: #e5eaf1
