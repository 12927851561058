@import "variables"

.dropdown-menu
    max-height: 80vh
    overflow-y: auto

.dropdown-toggle
    color: $deep-blue-900
    padding: calc(0.5rem - #{$btn-border-width}) calc(0.75rem - #{$btn-border-width})
    border-color: $charcoal-50
    border-radius: $border-radius-lg
    display: flex

.dropdown-toggle:hover
    background-color: $charcoal-25
    border-color: $charcoal-100
    color: $deep-blue-900

.dropdown-toggle:first-child:active
    background-color: $charcoal-50
    border-color: $charcoal-50
    color: $deep-blue-900

.dropdown-toggle::after
    content: url(/assets/dropdown_arrows.svg)
    border: none
    width: 24px
    height: 24px
    align-self: end
    vertical-align: bottom
    margin-left: auto
    margin-right: 0

.dropdown-toggle.compact::after
    content: none

.dropdown-toggle.btn
    padding: 8px 8px 8px 16px

.dropdown-toggle.btn .people
    content: url(/assets/people/normal.svg)
    align-self: center
    margin-right: 8px

.dropdown-toggle.btn .calendar
    content: url(/assets/calendar/normal.svg)
    align-self: center
    margin-right: 8px

.dropdown-toggle.btn .clock
    content: url(/assets/clock/normal.svg)
    align-self: center
    margin-right: 8px

.dropdown-menu
    border-color: $charcoal-50
    border-width: 1px
    border-style: solid
    border-radius: $border-radius
    padding: 10px

.dropdown-item:not(:last-child)
    margin-bottom: 10px

.dropdown-item
    color: $charcoal-700
    font-size: $font-size-large
    line-height: $line-height-large
    border: 1px solid $white
    padding: 6px 12px

.dropdown-item:hover
    background-color: $charcoal-25
    border: 1px solid $charcoal-50
    border-radius: $border-radius-lg

.dropdown-item:active
    background-color: $charcoal-50
    border: 1px solid $charcoal-50
    border-radius: $border-radius-lg
    color: $deep-blue-900

.bi-circle
    color: $charcoal-500
